<template>
  <div>
    <div v-if="compareOrder.length > 0">
      <h2 class="nunito ml-2 font-weight-bold red--text">Recent Order</h2>
      <v-slide-group class="pa-4 recent" show-arrows>
        <v-slide-item
          v-for="(v, i) in this.compareOrder.reverse().slice(0, 3)"
          :key="i"
          class="mr-3"
        >
          <v-card max-width="260" outlined>
            <v-list-item three-line @click="buyAgain(v)">
              <v-list-item-content>
                <div class="invoice mb-3">
                  {{ v.datetime }}
                </div>
                <v-list-item-title class="text-h8 mb-1">
                  {{ v.cart[0].name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  RM
                  <template v-if="v.cart[0].variant.length > 0">
                    {{ currency(v.cart[0].variant[0].price) }} x
                    {{ v.cart[0].quantity }}
                  </template>
                  <template v-else>
                    {{ currency(v.cart[0].unit_price) }} x
                    {{ v.cart[0].quantity }}
                  </template>
                  <br />
                  <div v-if="v.cart.length > 1">
                    and {{ v.cart.length - 1 }} more items
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-avatar tile size="80" color="grey">
                <v-img
                  v-if="v.cart[0].media_urls.length > 0"
                  max-height="120"
                  max-width="140"
                  :src="image(v.cart[0].media_urls[0])"
                ></v-img>
                <v-img
                  v-else
                  max-height="120"
                  max-width="140"
                  :src="emptyImage()"
                ></v-img>
              </v-list-item-avatar>
            </v-list-item>

            <v-card-actions class="red" @click="buyAgain(v)">
              <span
                style="cursor: pointer"
                class="white--text text-caption text-uppercase mx-auto"
                >order again</span
              >
            </v-card-actions>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </div>
    <div v-if="dialog">
      <v-dialog
        v-model="dialog"
        persistent
        overlay-color="white"
        overlay-opacity="0.9"
        max-width="500px"
      >
        <v-card>
          <div v-if="outOfStock">
            <v-card-title class="text-h7 red--text">
              The order contains an out-of-stock or sold-out item.
            </v-card-title>
            <v-card-text class="black--text">
              Please choose another order.
            </v-card-text>
          </div>
          <div v-else>
            <v-card-title class="text-h5"> Are you sure? </v-card-title>
            <v-card-text class="black--text">
              Proceeding will add the item into the cart.
            </v-card-text>
          </div>

          <v-card-actions>
            <v-spacer></v-spacer>
            <div v-if="outOfStock">
              <v-btn
                color="leaf"
                text
                @click="(dialog = false), (outOfStock = false)"
              >
                Proceed
              </v-btn>
            </div>
            <div v-else>
              <v-btn
                style="color: #f85757"
                text
                @click="(dialog = false), (outOfStock = false)"
              >
                Disagree
              </v-btn>
              <v-btn
                style="color: #f85757"
                text
                @click="addToCart"
                :disabled="outOfStock"
              >
                Agree
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Recent",
  data() {
    return {
      dialog: false,
      navigation: [
        { title: "history", component: () => import("../../History") },
      ],
      selected_product: null,
      outOfStock: false,
    };
  },
  watch: {
    $route: "updateRoute",
  },
  computed: {
    ...mapGetters({
      user: "getUsers",
      order: "getOrder",
      franchise: "getFranchise",
      product: "getProduct",
    }),
    selectedUser() {
      return this.user.find((d) => d.selected == true);
    },
    unselectedUser() {
      return this.user.filter((d) => d.selected == false);
    },
    compareOrder() {
      if (!this.selectedUser) return [];
      return this.order.filter(
        (d) => d.customer.name == this.selectedUser.name
      );
    },
    dayjs() {
      return require("dayjs");
    },
    currency() {
      return require("currency.js");
    },
  },
  methods: {
    toHistory(i) {
      this.$store.dispatch("updateSH", i);
      this.$router.push({
        name: "Page",
        params: { child: this.$route.params.child, page: "history" },
      });
    },
    image(i) {
      if (i.s512) {
        return i.s512;
      } else
        return (
          "https://s3-ap-southeast-1.amazonaws.com/assets.getorders/" +
          i.original
        );
    },
    emptyImage() {
      return (
        "https://s3-ap-southeast-1.amazonaws.com/assets.getorders/" +
        this.franchise.logo
      );
    },
    buyAgain(v) {
      this.selected_product = v.cart[0] || null;
      this.dialog = true;
      this.checkOutOfStock();
    },
    checkOutOfStock() {
      this.products.map((pastOrder) => {
        this.compareProduct(pastOrder);
      });
    },
    compareProduct(v) {
      this.product.map((current) => {
        if (v.id == current.id) {
          this.setOutOfStock(current);
        }
      });
    },
    setOutOfStock(v) {
      if (v.total_left == 0 || v.out_of_stock) {
        this.outOfStock = true;
        console.log("out of stock", this.outOfStock);
      }
    },
    addToCart() {
      if (this.selected_product) {
        this.$store.commit("addToCart", this.selected_product);
      }
      this.dialog = false;
    },
  },
};
</script>

<style>
.invoice {
  font-size: 10px;
  text-transform: uppercase;
}
</style>
